<template>
    <div ref="word">
       <div :class="{'text-hidden':showHidden && hidden}">{{ data }}</div>
       <div class="hidden-btn" v-if="showHidden" @click="toggle">
            {{ hidden?'展开':'收起' }}
       </div>
    </div>
</template>

<script>
// 文本展开、收起
export default {
    props:{
        data:{
            type:String
        },
    },
    data() {
        return {
            showHidden:false,
            hidden:false,
            domHeight:0
        };
    },
    mounted(){
         this.$nextTick(()=>{
            this.domHeight=this.$refs.word.offsetHeight
            if(this.domHeight>=40){
                this.showHidden = true
                this.hidden = true
            }
         });
    },
    methods: {
        toggle(){
            this.hidden = !this.hidden
        }
    },
};
</script>

<style lang="less" scoped>

.text-hidden {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.hidden-btn{
     color:#0090ff;
     &:hover{
        cursor:pointer;
     }

}
</style>