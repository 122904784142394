<template>
    <div class="container">
        <el-descriptions title="回访计划信息" :labelStyle="{'min-width':'80px'}" v-if="plan.id">
            <el-descriptions-item label="回访对象">{{ plan.user_name }}</el-descriptions-item>
            <el-descriptions-item label="执行人">{{ plan.admin_name }}</el-descriptions-item>
            <el-descriptions-item label="状态"> <el-tag v-if="plan.status == 0" type="info">待完成</el-tag>
                <el-tag v-if="plan.status == 1" type="warning">已取消</el-tag>
                <el-tag v-if="plan.status == 2">已完成</el-tag></el-descriptions-item>
            <el-descriptions-item label="计划内容">
                <textCollapse :data="plan.content"></textCollapse>
            </el-descriptions-item>
            <el-descriptions-item label="开始时间">
                {{ plan.start_time }}
            </el-descriptions-item>
            <el-descriptions-item label="结束时间"> {{ plan.end_time }}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="完成记录" v-if="record.id" :labelStyle="{'min-width':'80px'}">
            <el-descriptions-item label="联系方式">{{ record.type == 1 ? '电话' : '微信' }}</el-descriptions-item>
            <el-descriptions-item label="联系内容"> <textCollapse :data="record.contact_content"></textCollapse></el-descriptions-item>
            <el-descriptions-item label="完成时间"> 
                {{ record.contact_time }}
            </el-descriptions-item>
            <el-descriptions-item label="订单金额">
                ￥{{record.order_price}}
            </el-descriptions-item>
            <el-descriptions-item label="订单图片">
                <el-image
                v-for="(pic,index) in record.order_img.split(',')"
                style="width: 150px; height: 150px"
                :src="pic"
                fit="cover"
                :preview-src-list="record.order_img.split(',')"
                :key="index"
              ></el-image>
            </el-descriptions-item>
            <el-descriptions-item label="订单备注"> {{ record.order_remark }}</el-descriptions-item>
        </el-descriptions>
    </div>
      
</template>

<script>
import textCollapse from "@/components/text/textCollapse.vue";

export default {
    components: {
        textCollapse
    },
    data() {
        return {
            id: '',
            plan: {},
            record:{}
        };
    },

    mounted() {
        this.id = this.$route.params.id
        this.getPlanInfo()
    },

    methods: {
        getPlanInfo() {
            this.$axios({
                url: '/user/consultantPlan/findById',
                params: {
                    id: this.id
                }
            }).then(res => {
                this.plan = res.data.data
                if(this.plan.map.record){
                    this.record = this.plan.map.record
                }
            })
        }
    },
};
</script>

<style lang="less" scoped></style>